@import url(https://fonts.googleapis.com/css?family=Ubuntu&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  padding: 0;
  margin: 0;
  position: relative;
  font-family: 'Fira Code'
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  padding: 0;
  margin: 0;
  font-family: 'Open Sans', 'Ubuntu', 'Nunito';
  word-wrap: break-word;
}

html {
  scroll-behavior: smooth;
}

.bg-gold {
  color: gold;
}

@media screen and (max-width:320px) {
  .login-container {
    margin: 20px;
  }
}

@media screen and (max-width:749px) {
  .registration-container {
    margin: 20px;
  }
  .registration-container.p-7 {
    padding: 0;
    margin: 20px;
  }
  .side {
    display: none;
  }
  .conversation__container {
    display: none;
  }
  .vp-dead {
    display: block;
  }
  .login-container {
    margin: 20px;
  }
}

body {
  margin: 0;
  /* font-family: "Nunito Sans",sans-serif; */
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.7;
  color: #8492a6;
  text-align: left;
  background-color: #ecebeb;
}

.dummy-positioning {
  margin-top: 3em;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.success-icon {
  display: inline-block;
  width: 8em;
  height: 8em;
  font-size: 20px;
  border-radius: 50%;
  border: 4px solid #96df8f;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  transform-origin: center;
  -webkit-animation: showSuccess 180ms ease-in-out;
  animation: showSuccess 180ms ease-in-out;
  transform: scale(1);
}

.success-icon__tip, .success-icon__long {
  display: block;
  position: absolute;
  height: 4px;
  background-color: #96df8f;
  border-radius: 10px;
}

.success-icon__tip {
  width: 2.4em;
  top: 4.3em;
  left: 1.4em;
  transform: rotate(45deg);
  -webkit-animation: tipInPlace 300ms ease-in-out;
  animation: tipInPlace 300ms ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 180ms;
  animation-delay: 180ms;
  visibility: hidden;
}

.success-icon__long {
  width: 4em;
  transform: rotate(-45deg);
  top: 3.70em;
  left: 2.75em;
  -webkit-animation: longInPlace 140ms ease-in-out;
  animation: longInPlace 140ms ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  visibility: hidden;
  -webkit-animation-delay: 440ms;
  animation-delay: 440ms;
}

@-webkit-keyframes showSuccess {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes showSuccess {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@-webkit-keyframes tipInPlace {
  from {
    width: 0em;
    top: 0em;
    left: -1.6em;
  }
  to {
    width: 2.4em;
    top: 4.3em;
    left: 1.4em;
    visibility: visible;
  }
}

@keyframes tipInPlace {
  from {
    width: 0em;
    top: 0em;
    left: -1.6em;
  }
  to {
    width: 2.4em;
    top: 4.3em;
    left: 1.4em;
    visibility: visible;
  }
}

@-webkit-keyframes longInPlace {
  from {
    width: 0em;
    top: 5.1em;
    left: 3.2em;
  }
  to {
    width: 4em;
    top: 3.70em;
    left: 2.75em;
    visibility: visible;
  }
}

@keyframes longInPlace {
  from {
    width: 0em;
    top: 5.1em;
    left: 3.2em;
  }
  to {
    width: 4em;
    top: 3.70em;
    left: 2.75em;
    visibility: visible;
  }
}

.env--container {
  overflow-y: hidden;
}

.Toastify__toast--success {
  background-color: #2dca8c !important;
  border-color: #2dca8c;
}

.Toastify__toast--error {
  color: #fff;
  background-color: #ff5c75 !important;
}

.Toastify__toast--warning {
  color: #fff;
  background-color: #ffbe3d !important;
}

.Toastify__toast--info {
  color: #fff;
  background-color: #50b5ff !important;
}
